import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { AppState } from '../reducer';
import { signIn } from '../actions';

interface WelcomeProps {
  readonly user: any;
  readonly signIn: () => void;
  readonly isSignedIn: boolean;
}

class Welcome extends React.Component<WelcomeProps> {
  userAttribute(attr: string) {
    if (this.props.user && this.props.user.attributes) {
      return this.props.user.attributes[attr];
    }
  }

  render() {
    const { isSignedIn, signIn } = this.props;
    const firstName = this.userAttribute('given_name');

    return (
      <div>
        {!isSignedIn && (
          <div className="jumbotron">
            <h1 className="display-4">Members Only!</h1>
            <p className="lead">Sign in to access exclusive band-only content</p>
            <button className="btn btn-info btn-lg" onClick={() => signIn()}>
              Sign in
            </button>
          </div>
        )}
        {isSignedIn && (
          <div className="jumbotron">
            <h1 className="display-4">Welcome, {firstName}</h1>
            <p className="lead">You are now signed in</p>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    isSignedIn: state.isSignedIn,
    user: state.user
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<{}, {}, AnyAction>) {
  return {
    signIn: () => dispatch(signIn())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Welcome);
