import {
  USER_SIGNED_IN,
  USER_SIGNED_OUT,
  METADATA_RECEIVED,
  NEW_METADATA_EVENT,
  CHANGE_NEW_METADATA_EVENT,
  CANCEL_NEW_METADATA_EVENT,
  CREATE_METADATA_EVENT,
  EDIT_METADATA_EVENT,
  UPDATE_METADATA_EVENT,
  DELETE_METADATA_EVENT,
  NEW_METADATA_SOCIAL_MEDIUM,
  CHANGE_METADATA_SOCIAL_MEDIUM,
  CANCEL_METADATA_SOCIAL_MEDIUM,
  CREATE_METADATA_SOCIAL_MEDIUM,
  EDIT_METADATA_SOCIAL_MEDIUM,
  UPDATE_METADATA_SOCIAL_MEDIUM,
  DELETE_METADATA_SOCIAL_MEDIUM,
  METADATA_SAVED,
  METADATA_SAVE_FAILED
} from './actions';

export interface Event {
  readonly date: string;
  readonly venue: string;
  readonly city: string;
  readonly url: string;
}

export interface SocialMedium {
  readonly icon: string;
  readonly label: string;
  readonly url: string;
}

export interface Metadata {
  events: Event[];
  socialMedia: SocialMedium[];
}

export interface AppState {
  readonly isSignedIn: boolean;
  readonly user: any;
  readonly form?: {
    type: string;
    index?: number;
    attributes: any;
  };
  readonly metadata: Metadata;
  readonly isMetadataDirty: boolean;
  readonly errorMessage: string | null;
}

const initialState: AppState = {
  isSignedIn: false,
  user: null,
  isMetadataDirty: false,
  errorMessage: null,
  metadata: {
    events: [],
    socialMedia: []
  }
};

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return [year, month.toString().padStart(2, '0'), day.toString().padStart(2, '0')].join('-');
}

function sortEvents(events: Event[]): Event[] {
  return events.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });
}

export default function (state: AppState = initialState, action: any): AppState {
  switch (action.type) {
    case USER_SIGNED_IN:
      return Object.assign({}, state, {
        isSignedIn: true,
        user: action.user
      });
    case USER_SIGNED_OUT:
      return Object.assign({}, state, {
        isSignedIn: false,
        user: null
      });
    case METADATA_RECEIVED:
      return Object.assign({}, state, {
        metadata: action.metadata,
        isMetadataDirty: false,
        errorMessage: null
      });
    case METADATA_SAVED:
      return Object.assign({}, state, {
        metadata: Object.assign({}, state.metadata, {
          events: state.metadata.events.map((event: any) => Object.assign({}, event)),
          socialMedia: state.metadata.socialMedia.map((event: any) => Object.assign({}, event))
        }),
        isMetadataDirty: false,
        errorMessage: null
      });
    case METADATA_SAVE_FAILED:
      return Object.assign({}, state, {
        errorMessage: action.errorMessage
      });
    case NEW_METADATA_EVENT:
      return {
        ...state,
        form: {
          type: 'EVENT',
          attributes: {
            date: formatDate(new Date()),
            venue: '',
            city: '',
            url: ''
          }
        }
      };
    case CHANGE_NEW_METADATA_EVENT:
      return {
        ...state,
        form: {
          ...state.form,
          type: 'EVENT',
          attributes: action.event
        }
      };
    case CANCEL_NEW_METADATA_EVENT:
      return { ...state, form: undefined };
    case CREATE_METADATA_EVENT:
      return {
        ...state,
        form: undefined,
        metadata: {
          ...state.metadata,
          events: sortEvents([...state.metadata.events, action.event])
        },
        isMetadataDirty: true
      };
    case EDIT_METADATA_EVENT:
      return {
        ...state,
        form: {
          type: 'EVENT',
          index: action.index,
          attributes: action.event
        }
      };
    case UPDATE_METADATA_EVENT:
      return {
        ...state,
        form: undefined,
        metadata: {
          ...state.metadata,
          events: sortEvents([
            ...state.metadata.events.slice(0, action.index),
            action.event,
            ...state.metadata.events.slice(action.index + 1)
          ])
        },
        isMetadataDirty: true
      };
    case DELETE_METADATA_EVENT:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          events: [...state.metadata.events.slice(0, action.index), ...state.metadata.events.slice(action.index + 1)]
        },
        isMetadataDirty: true
      };

    case NEW_METADATA_SOCIAL_MEDIUM:
      return {
        ...state,
        form: {
          type: 'SOCIAL_MEDIUM',
          attributes: {
            icon: '',
            label: '',
            url: ''
          }
        }
      };
    case CHANGE_METADATA_SOCIAL_MEDIUM:
      return {
        ...state,
        form: {
          ...state.form,
          type: 'SOCIAL_MEDIUM',
          attributes: action.socialMedium
        }
      };
    case CANCEL_METADATA_SOCIAL_MEDIUM:
      return { ...state, form: undefined };
    case CREATE_METADATA_SOCIAL_MEDIUM:
      return {
        ...state,
        form: undefined,
        metadata: {
          ...state.metadata,
          socialMedia: [...state.metadata.socialMedia, action.socialMedium]
        },
        isMetadataDirty: true
      };
    case EDIT_METADATA_SOCIAL_MEDIUM:
      return {
        ...state,
        form: {
          type: 'SOCIAL_MEDIUM',
          index: action.index,
          attributes: action.socialMedium
        }
      };
    case UPDATE_METADATA_SOCIAL_MEDIUM:
      return {
        ...state,
        form: undefined,
        metadata: {
          ...state.metadata,
          socialMedia: [
            ...state.metadata.socialMedia.slice(0, action.index),
            action.socialMedium,
            ...state.metadata.socialMedia.slice(action.index + 1)
          ]
        },
        isMetadataDirty: true
      };
    case DELETE_METADATA_SOCIAL_MEDIUM:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          socialMedia: [
            ...state.metadata.socialMedia.slice(0, action.index),
            ...state.metadata.socialMedia.slice(action.index + 1)
          ]
        },
        isMetadataDirty: true
      };

    default:
      return state;
  }
}
