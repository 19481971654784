import { Auth, Analytics } from 'aws-amplify';
import { merge } from 'lodash';

export function configureAuth() {
  const commonConfig = {
    region: 'us-east-1',
    userPoolId: 'us-east-1_9l9M6j3no',
    oauth: {
      domain: 'nightowlsband.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      responseType: 'code' // or token
    }
  };

  const developmentConfig = {
    userPoolWebClientId: '6tsmim6r3hdo2b4g3jp3a9u5qh',
    oauth: {
      redirectSignIn: 'http://localhost:3000/',
      redirectSignOut: 'http://localhost:3000/'
    }
  };

  const productionConfig = {
    userPoolWebClientId: '3a5gt8g68otaussri0vsbk06vd',
    oauth: {
      redirectSignIn: 'https://members.nightowlsband.com/',
      redirectSignOut: 'https://members.nightowlsband.com/'
    }
  };

  const config =
    process.env.NODE_ENV === 'production'
      ? merge({}, commonConfig, productionConfig)
      : merge({}, commonConfig, developmentConfig);

  Auth.configure(config);
}

export function configureAnalytics() {
  const config = { disabled: true };

  Analytics.configure(config);
}
