import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { AppState } from '../reducer';
import { initAuth } from '../actions';

import Navigator from './navigator';
import Welcome from './welcome';
import { ConnectedSiteMetadata } from './site_metadata';
import Media from './media';
import Music from './music';

interface AppProps extends RouteComponentProps {
  readonly initAuth: () => void;
}

class App extends React.Component<AppProps> {
  componentDidMount() {
    this.props.initAuth();
  }

  render() {
    return (
      <div>
        <header>
          <Navigator />
        </header>
        <main className="mt-3">
          <div className="container">
            <Route exact path="/" component={Welcome} />
            <Route path="/site" component={ConnectedSiteMetadata} />
            <Route path="/media" component={Media} />
            <Route path="/music" component={Music} />
          </div>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {};
}

function mapDispatchToProps(dispatch: ThunkDispatch<{}, {}, AnyAction>) {
  return {
    initAuth: () => dispatch(initAuth())
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
