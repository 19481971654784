import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { AppState, Event } from '../../reducer';
import * as actions from '../../actions';
import { yesterday } from '../../lib/time';
import { Table, TableHeader, TableBody, TableFooter, TableRow, TableHeading, TableData } from '../table';

interface EventsListStateProps {
  readonly events: Event[];
}

interface EventsListDispatchProps {
  readonly newEvent: () => void;
  readonly editEvent: (event: Event, index: number) => void;
  readonly deleteEvent: (index: number) => void;
}

function mapStateToProps(state: AppState): EventsListStateProps {
  return {
    events: state.metadata.events
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<{}, {}, AnyAction>): EventsListDispatchProps {
  return {
    newEvent: () => dispatch(actions.newMetadataEvent()),
    editEvent: (event, index) => dispatch(actions.editMetadataEvent(event, index)),
    deleteEvent: (index) => dispatch(actions.deleteMetadataEvent(index))
  };
}

export type EventsListProps = EventsListStateProps & EventsListDispatchProps;

export interface EventsListState {
  readonly showPastEvents: boolean;
}

export class EventsList extends React.Component<EventsListProps, EventsListState> {
  constructor(props: EventsListProps) {
    super(props);

    this.state = {
      showPastEvents: false
    };

    this.toggleShowPastEvents = this.toggleShowPastEvents.bind(this);
  }

  toggleShowPastEvents() {
    this.setState({
      showPastEvents: !this.state.showPastEvents
    });
  }

  formatDate(date: Date | string): string {
    if (typeof date == 'object') {
      return [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getDate().toString().padStart(2, '0')
      ].join('-');
    }

    return date;
  }

  render() {
    return (
      <div>
        <header>
          <h2>
            {this.state.showPastEvents && <span>All Events</span>}
            {this.state.showPastEvents || <span>Upcoming Events</span>}
            <div className="float-right">
              <button className="btn btn-default" onClick={this.toggleShowPastEvents}>
                {this.state.showPastEvents && <span>hide past</span>}
                {this.state.showPastEvents || <span>show all</span>}
              </button>
            </div>
          </h2>
        </header>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeading>Date</TableHeading>
              <TableHeading>Venue</TableHeading>
              <TableHeading>City</TableHeading>
              <TableHeading>URL</TableHeading>
              <TableHeading></TableHeading>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.props.events.map((event: any, index: number) => {
              const { date, venue, city, url } = event;

              const isUpcoming = yesterday().getTime() < new Date(event.date).getTime();
              const rowDisplay = isUpcoming || this.state.showPastEvents ? 'table-row' : 'none';

              return (
                <TableRow key={index} style={{ display: rowDisplay }}>
                  <TableData>{this.formatDate(date)}</TableData>
                  <TableData>{venue}</TableData>
                  <TableData>{city}</TableData>
                  <TableData>
                    {url && (
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        link
                      </a>
                    )}
                  </TableData>
                  <TableData align="right">
                    <div className="btn-group">
                      <button
                        title="edit"
                        className="btn btn-sm btn-light"
                        onClick={() => this.props.editEvent(event, index)}
                      >
                        <i className="fa fa-sm fa-pencil" />
                      </button>
                      <button
                        title="delete"
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          if (window.confirm('Are you sure?')) {
                            this.props.deleteEvent(index);
                          }
                        }}
                      >
                        <i className="fa fa-sm fa-trash" />
                      </button>
                    </div>
                  </TableData>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableData colSpan={5} align="right">
                <button className="btn btn-sm btn-success" onClick={() => this.props.newEvent()}>
                  <i className="fa fa-plus mr-1" />
                  Add Event
                </button>
              </TableData>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

export const ConnectedEventsList = connect(mapStateToProps, mapDispatchToProps)(EventsList);
