import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { AppState, SocialMedium } from '../../reducer';
import * as actions from '../../actions';
import { Table, TableHeader, TableBody, TableFooter, TableRow, TableHeading, TableData } from '../table';

interface SocialMediumStateProps {
  readonly socialMedia: SocialMedium[];
}

interface SocialMediumDispatchProps {
  readonly newSocialMedium: () => void;
  readonly editSocialMedium: (socialMedium: SocialMedium, index: number) => void;
  readonly deleteSocialMedium: (index: number) => void;
}

function mapStateToProps(state: AppState): SocialMediumStateProps {
  return {
    socialMedia: state.metadata.socialMedia
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<{}, {}, AnyAction>): SocialMediumDispatchProps {
  return {
    newSocialMedium: () => dispatch(actions.newMetadataSocialMedium()),
    editSocialMedium: (socialMedium, index) => dispatch(actions.editMetadataSocialMedium(socialMedium, index)),
    deleteSocialMedium: (index) => dispatch(actions.deleteMetadataSocialMedium(index))
  };
}

export type SocialMediaListProps = SocialMediumStateProps & SocialMediumDispatchProps;

export class SocialMediaList extends React.Component<SocialMediaListProps> {
  render() {
    return (
      <div>
        <header>
          <h2>Social Media</h2>
        </header>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeading>Icon</TableHeading>
              <TableHeading>Label</TableHeading>
              <TableHeading>URL</TableHeading>
              <TableHeading></TableHeading>
            </TableRow>
          </TableHeader>
          <TableBody>
            {this.props.socialMedia.map((socialMedium: any, index: number) => {
              const { icon, label, url } = socialMedium;
              return (
                <TableRow key={index}>
                  <TableData>
                    <i className={`fa fa-lg fa-${icon}`} />
                  </TableData>
                  <TableData>{label}</TableData>
                  <TableData>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {url}
                    </a>
                  </TableData>
                  <TableData align="right">
                    <div className="btn-group">
                      <button
                        title="edit"
                        className="btn btn-sm btn-light"
                        onClick={() => this.props.editSocialMedium(socialMedium, index)}
                      >
                        <i className="fa fa-sm fa-pencil" />
                      </button>
                      <button
                        title="delete"
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          if (window.confirm('Are you sure?')) {
                            this.props.deleteSocialMedium(index);
                          }
                        }}
                      >
                        <i className="fa fa-sm fa-trash" />
                      </button>
                    </div>
                  </TableData>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableData colSpan={4} align="right">
                <button className="btn btn-sm btn-success" onClick={() => this.props.newSocialMedium()}>
                  <i className="fa fa-plus mr-1" />
                  New Social Medium
                </button>
              </TableData>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }
}

export const ConnectedSocialMediaList = connect(mapStateToProps, mapDispatchToProps)(SocialMediaList);
