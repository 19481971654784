import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as actions from '../actions';
import { AppState } from '../reducer';
import { Modal, ModalHeader, ModalTitle, ModalBody, ModalBackdrop } from './modal';
import {
  ConnectedEventsList,
  ConnectedEventForm,
  ConnectedSocialMediaList,
  ConnectedSocialMediumForm
} from './metadata';

import 'font-awesome/css/font-awesome.min.css';

interface SiteMetadataStateProps {
  readonly isSignedIn: boolean;
  readonly metadata: any;
  readonly isMetadataDirty: boolean;
  readonly errorMessage: string | null;
  readonly form?: {
    type: string;
    index?: number;
    attributes: any;
  };
}

interface SiteMetadataDispatchProps {
  readonly fetchMetadata: () => void;
  readonly saveMetadata: (metadata: any) => void;
  readonly cancelMetadataEvent: () => void;
  readonly cancelMetadataSocialMedium: () => void;
}

function mapStateToProps(state: AppState): SiteMetadataStateProps {
  return {
    isSignedIn: state.isSignedIn,
    metadata: state.metadata,
    isMetadataDirty: state.isMetadataDirty,
    errorMessage: state.errorMessage,
    form: state.form
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<{}, {}, AnyAction>): SiteMetadataDispatchProps {
  return {
    fetchMetadata: () => dispatch(actions.fetchMetadata()),
    saveMetadata: (metadata: any) => dispatch(actions.saveMetadata(metadata)),
    cancelMetadataEvent: () => dispatch(actions.cancelMetadataEvent()),
    cancelMetadataSocialMedium: () => dispatch(actions.cancelMetadataSocialMedium())
  };
}

export type SiteMetadataProps = SiteMetadataStateProps & SiteMetadataDispatchProps;

export class SiteMetadata extends React.Component<SiteMetadataProps> {
  componentDidMount() {
    this.props.fetchMetadata();
  }

  render() {
    const { isSignedIn } = this.props;

    if (isSignedIn) {
      return this.renderAuthenticated();
    } else {
      return this.renderUnauthenticated();
    }
  }

  renderUnauthenticated() {
    return <h1>401 Unauthenticated</h1>;
  }

  renderAuthenticated() {
    return (
      <div>
        <header>
          <h1>Public Site Metadata</h1>
          <div className="alert alert-warning" role="alert">
            Be careful, this updates the information on our public website!
          </div>
          {this.props.errorMessage && (
            <div className="alert alert-danger" role="alert">
              {this.props.errorMessage}
            </div>
          )}
        </header>
        <section>
          <ConnectedEventsList />
          {this.props.form && this.props.form.type === 'EVENT' && (
            <Modal onClose={this.props.cancelMetadataEvent}>
              <ModalHeader onClose={this.props.cancelMetadataEvent}>
                <ModalTitle>New Event</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <ConnectedEventForm />
              </ModalBody>
            </Modal>
          )}
        </section>
        <section className="mt-5">
          <ConnectedSocialMediaList />
          {this.props.form && this.props.form.type === 'SOCIAL_MEDIUM' && (
            <Modal onClose={this.props.cancelMetadataSocialMedium}>
              <ModalHeader onClose={this.props.cancelMetadataSocialMedium}>
                <ModalTitle>New Social Medium</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <ConnectedSocialMediumForm />
              </ModalBody>
            </Modal>
          )}
        </section>
        <footer className="mt-3">
          <form>
            <button
              className="btn btn-primary"
              disabled={!this.props.isMetadataDirty}
              onClick={(ev) => {
                ev.preventDefault();
                this.props.saveMetadata(this.props.metadata);
              }}
            >
              Save All
            </button>
            {this.props.isMetadataDirty && (
              <button
                className="btn btn-secondary ml-2"
                onClick={(ev) => {
                  ev.preventDefault();
                  this.props.fetchMetadata();
                }}
              >
                Discard Changes
              </button>
            )}
          </form>
          {this.props.form && <ModalBackdrop />}
        </footer>
      </div>
    );
  }
}

export const ConnectedSiteMetadata = connect(mapStateToProps, mapDispatchToProps)(SiteMetadata);
