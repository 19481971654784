import React from 'react';
import { connect } from 'react-redux';

interface MediaProps {
  readonly isSignedIn: boolean;
}

class Media extends React.Component<MediaProps> {
  render() {
    const { isSignedIn } = this.props;

    if (isSignedIn) {
      return this.renderAuthenticated();
    } else {
      return this.renderUnauthenticated();
    }
  }

  renderUnauthenticated() {
    return <h1>401 Unauthenticated</h1>;
  }

  renderAuthenticated() {
    return (
      <div className="jumbotron">
        <h1 className="display-4">Media</h1>
        <p className="lead">Coming soon...</p>
      </div>
    );
  }
}

function mapStateToProps(state: MediaProps) {
  return {
    isSignedIn: state.isSignedIn
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Media);
