import React from 'react';

export interface FormProps {
  readonly onSubmit?: (ev: React.SyntheticEvent) => void;
}

export class Form extends React.Component<FormProps> {
  render() {
    return <form onSubmit={this.props.onSubmit}>{this.props.children}</form>;
  }
}

export class FormGroup extends React.Component<FormProps> {
  render() {
    return <div className="form-group">{this.props.children}</div>;
  }
}

export interface HtmlInputProps<Type> {
  readonly name: string;
  readonly label?: string;
  readonly value?: string;
  readonly onChange?: (ev: React.ChangeEvent<Type>) => void;
}

export abstract class HtmlInput<Type> extends React.Component<HtmlInputProps<Type>> {
  renderLabel() {
    return (
      <label className="col-form-label" htmlFor={this.props.name}>
        {this.props.label}
      </label>
    );
  }

  abstract renderInput(): React.ReactNode;

  render() {
    return (
      <div>
        {this.props.label && this.renderLabel()}
        {this.renderInput()}
      </div>
    );
  }
}

export class TextInput extends HtmlInput<HTMLInputElement> {
  renderInput() {
    return (
      <input
        type="text"
        className="form-control"
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
      />
    );
  }
}

export class DateInput extends HtmlInput<HTMLInputElement> {
  renderInput() {
    return (
      <input
        type="date"
        className="form-control"
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
      />
    );
  }
}

export class SelectInput extends HtmlInput<HTMLSelectElement> {
  renderInput() {
    return (
      <select className="form-control" name={this.props.name} value={this.props.value} onChange={this.props.onChange}>
        {this.props.children}
      </select>
    );
  }
}

export interface SelectOptionProps {
  readonly value: string;
}

export class SelectOption extends React.Component<SelectOptionProps> {
  render() {
    return <option value={this.props.value}>{this.props.children}</option>;
  }
}
