import { Auth, Hub } from 'aws-amplify';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { requireEnvironmentVariable } from './lib';

const METADATA_REST_API_ENDPOINT = requireEnvironmentVariable('REACT_APP_METADATA_REST_API_ENDPOINT');

export const USER_SIGNED_IN = 'USER_SIGNED_IN';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const METADATA_RECEIVED = 'METADATA_RECEIVED';

export const NEW_METADATA_EVENT = 'ADD_METADATA_EVENT';
export const CHANGE_NEW_METADATA_EVENT = 'CHANGE_NEW_METADATA_EVENT';
export const CANCEL_NEW_METADATA_EVENT = 'CANCEL_NEW_METADATA_EVENT';
export const CREATE_METADATA_EVENT = 'CREATE_METADATA_EVENT';
export const EDIT_METADATA_EVENT = 'EDIT_METADATA_EVENT';
export const UPDATE_METADATA_EVENT = 'UPDATE_METADATA_EVENT';
export const DELETE_METADATA_EVENT = 'DELETE_METADATA_EVENT';

export const NEW_METADATA_SOCIAL_MEDIUM = 'ADD_METADATA_SOCIAL_MEDIUM';
export const CHANGE_METADATA_SOCIAL_MEDIUM = 'CHANGE_METADATA_SOCIAL_MEDIUM';
export const CANCEL_METADATA_SOCIAL_MEDIUM = 'CANCEL_METADATA_SOCIAL_MEDIUM';
export const CREATE_METADATA_SOCIAL_MEDIUM = 'CREATE_METADATA_SOCIAL_MEDIUM';
export const EDIT_METADATA_SOCIAL_MEDIUM = 'EDIT_METADATA_SOCIAL_MEDIUM';
export const UPDATE_METADATA_SOCIAL_MEDIUM = 'UPDATE_METADATA_SOCIAL_MEDIUM';
export const DELETE_METADATA_SOCIAL_MEDIUM = 'DELETE_METADATA_SOCIAL_MEDIUM';

export const METADATA_SAVED = 'METADATA_SAVED';
export const METADATA_SAVE_FAILED = 'METADATA_SAVE_FAILED';

async function processFetchResponse(response: Response): Promise<Object | null> {
  return new Promise((resolve, reject) => {
    response
      .json()
      .catch(() => null)
      .then((data) => {
        if (response.status < 400) {
          return resolve(data);
        }
        return reject(data);
      });
  });
}

export function userSignedIn(user: any) {
  return {
    type: USER_SIGNED_IN,
    user
  };
}

export function userSignedOut() {
  return {
    type: USER_SIGNED_OUT
  };
}

export function initAuth() {
  async function getAuthenticatedUser() {
    return new Promise((resolve) => {
      Auth.currentAuthenticatedUser()
        .then((user) => resolve(user))
        .catch(() => {
          // ignore error
          return;
        });
    });
  }

  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signIn') {
        getAuthenticatedUser().then((user) => dispatch(userSignedIn(user)));
      }
    });
    getAuthenticatedUser().then((user) => dispatch(userSignedIn(user)));
  };
}

export function signIn() {
  return () => Auth.federatedSignIn();
}

export function signOut() {
  return () => Auth.signOut();
}

export function metadataReceived(metadata: any) {
  return {
    type: METADATA_RECEIVED,
    metadata
  };
}

export function fetchMetadata() {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    fetch(`${METADATA_REST_API_ENDPOINT}/metadata.json?page=home`, {
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((metadata) => {
        dispatch(metadataReceived(metadata));
      });
  };
}

export function newMetadataEvent() {
  return {
    type: NEW_METADATA_EVENT
  };
}

export function changeMetadataEvent(event: any) {
  return {
    type: CHANGE_NEW_METADATA_EVENT,
    event
  };
}

export function cancelMetadataEvent() {
  return {
    type: CANCEL_NEW_METADATA_EVENT
  };
}

export function createMetadataEvent(event: any) {
  return {
    type: CREATE_METADATA_EVENT,
    event
  };
}

export function editMetadataEvent(event: any, index: number) {
  return {
    type: EDIT_METADATA_EVENT,
    event,
    index
  };
}

export function updateMetadataEvent(event: any, index: number) {
  return {
    type: UPDATE_METADATA_EVENT,
    event,
    index
  };
}

export function deleteMetadataEvent(index: number) {
  return {
    type: DELETE_METADATA_EVENT,
    index
  };
}

export function newMetadataSocialMedium() {
  return {
    type: NEW_METADATA_SOCIAL_MEDIUM
  };
}

export function changeMetadataSocialMedium(socialMedium: any) {
  return {
    type: CHANGE_METADATA_SOCIAL_MEDIUM,
    socialMedium
  };
}

export function cancelMetadataSocialMedium() {
  return {
    type: CANCEL_METADATA_SOCIAL_MEDIUM
  };
}

export function createMetadataSocialMedium(socialMedium: any) {
  return {
    type: CREATE_METADATA_SOCIAL_MEDIUM,
    socialMedium
  };
}

export function editMetadataSocialMedium(socialMedium: any, index: number) {
  return {
    type: EDIT_METADATA_SOCIAL_MEDIUM,
    socialMedium,
    index
  };
}

export function updateMetadataSocialMedium(socialMedium: any, index: number) {
  return {
    type: UPDATE_METADATA_SOCIAL_MEDIUM,
    socialMedium,
    index
  };
}

export function deleteMetadataSocialMedium(index: number) {
  return {
    type: DELETE_METADATA_SOCIAL_MEDIUM,
    index
  };
}

export function metadataSaved() {
  return {
    type: METADATA_SAVED
  };
}

export function metadataSaveFailed(errorMessage: string) {
  return {
    type: METADATA_SAVE_FAILED,
    errorMessage
  };
}

export function saveMetadata(metadata: any) {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    fetch(`${METADATA_REST_API_ENDPOINT}/metadata.json`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(metadata)
    })
      .then(processFetchResponse)
      .then(() => {
        dispatch(metadataSaved());
      })
      .catch((error: any) => {
        const errorMessage = error && error.error ? `${error.error.code}: ${error.error.message}` : 'Unknown error';
        console.error(error);
        dispatch(metadataSaveFailed(errorMessage));
      });
  };
}
