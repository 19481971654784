import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { AppState } from '../reducer';
import { signIn, signOut } from '../actions';

interface NavigatorProps {
  readonly isSignedIn: boolean;
  readonly user: any;
  readonly signIn: () => void;
  readonly signOut: () => void;
}

class Navigator extends React.Component<NavigatorProps> {
  userAttribute(attr: string) {
    if (this.props.user && this.props.user.attributes) {
      return this.props.user.attributes[attr];
    }
  }

  render() {
    const isSignedIn = this.props.isSignedIn;
    const firstName = this.userAttribute('given_name');

    return (
      <nav className="navbar navbar-dark bg-dark navbar-expand-sm">
        <Link to="/" className="navbar-brand">
          Night Owls Members
        </Link>
        {isSignedIn && (
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to="/site" className="nav-link">
                  Site
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/media" className="nav-link">
                  Media
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/music" className="nav-link">
                  Music
                </Link>
              </li>
            </ul>
            <span className="navbar-text mr-3">Hi, {firstName}</span>
            <button className="btn btn-outline-info" onClick={() => this.props.signOut()}>
              Sign out
            </button>
          </div>
        )}
        {!isSignedIn && (
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto" />
            <button className="btn btn-outline-info" onClick={() => this.props.signIn()}>
              Sign in
            </button>
          </div>
        )}
      </nav>
    );
  }
}

function mapStateToProps(state: AppState) {
  return {
    isSignedIn: state.isSignedIn,
    user: state.user
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<{}, {}, AnyAction>) {
  return {
    signIn: () => dispatch(signIn()),
    signOut: () => dispatch(signOut())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigator);
