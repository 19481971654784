import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { AppState, SocialMedium } from '../../reducer';
import * as actions from '../../actions';
import { Form, FormGroup, TextInput, SelectInput, SelectOption } from '../form';

export enum SocialMediaIcons {
  FACEBOOK = 'facebook-square',
  TWITTER = 'twitter-square',
  LINKED_IN = 'linkedin',
  GOOGLE_PLUS = 'google-plus',
  YOUTUBE = 'youtube',
  PINTEREST = 'pinterest-square',
  INSTAGRAM = 'instagram'
}

interface SocialMediumFormStateProps {
  readonly socialMedium: SocialMedium;
  readonly index?: number;
}

interface SocialMediumFormDispatchProps {
  readonly onCancel: () => void;
  readonly onChange: (socialMedium: SocialMedium) => void;
  readonly onCreate: (socialMedium: SocialMedium) => void;
  readonly onUpdate: (socialMedium: SocialMedium, index: number) => void;
}

function mapStateToProps(state: AppState): SocialMediumFormStateProps {
  if (!state.form) {
    throw new Error('state.form undefined');
  }

  return {
    socialMedium: state.form.attributes as SocialMedium,
    index: state.form.index
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<{}, {}, AnyAction>): SocialMediumFormDispatchProps {
  return {
    onCancel: () => dispatch(actions.cancelMetadataEvent()),
    onChange: (socialMedium) => dispatch(actions.changeMetadataSocialMedium(socialMedium)),
    onCreate: (socialMedium) => dispatch(actions.createMetadataSocialMedium(socialMedium)),
    onUpdate: (socialMedium, index) => dispatch(actions.updateMetadataSocialMedium(socialMedium, index))
  };
}

export type SocialMediumFormProps = SocialMediumFormStateProps & SocialMediumFormDispatchProps;

export class SocialMediumForm extends React.Component<SocialMediumFormProps> {
  constructor(props: SocialMediumFormProps) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(ev: React.SyntheticEvent) {
    ev.preventDefault();
    if (typeof this.props.index === 'undefined') {
      this.props.onCreate(this.props.socialMedium);
    } else {
      this.props.onUpdate(this.props.socialMedium, this.props.index);
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <SelectInput
            name="icon"
            label="Icon:"
            value={this.props.socialMedium.icon}
            onChange={(ev) => this.props.onChange({ ...this.props.socialMedium, icon: ev.target.value })}
          >
            <SelectOption value=""></SelectOption>
            {Object.values(SocialMediaIcons).map((icon) => {
              return (
                <SelectOption key={icon} value={icon}>
                  {icon}
                </SelectOption>
              );
            })}
          </SelectInput>
        </FormGroup>
        <FormGroup>
          <TextInput
            name="label"
            label="Label:"
            value={this.props.socialMedium.label}
            onChange={(ev) => this.props.onChange({ ...this.props.socialMedium, label: ev.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            name="url"
            label="URL:"
            value={this.props.socialMedium.url}
            onChange={(ev) => this.props.onChange({ ...this.props.socialMedium, url: ev.target.value })}
          />
        </FormGroup>
        <div className="float-right">
          <button
            type="button"
            className="btn btn-secondary mr-2"
            data-dismiss="modal"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-success">
            Save
          </button>
        </div>
      </Form>
    );
  }
}

export const ConnectedSocialMediumForm = connect(mapStateToProps, mapDispatchToProps)(SocialMediumForm);
