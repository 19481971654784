import React from 'react';

type HorizontalAlignment = 'left' | 'center' | 'right' | 'justify' | 'char';

export class Table extends React.Component {
  render() {
    return <table className="table">{this.props.children}</table>;
  }
}

export class TableHeader extends React.Component {
  render() {
    return <thead>{this.props.children}</thead>;
  }
}

export class TableBody extends React.Component {
  render() {
    return <tbody>{this.props.children}</tbody>;
  }
}

export class TableFooter extends React.Component {
  render() {
    return <tfoot>{this.props.children}</tfoot>;
  }
}

export interface TableRowProps {
  readonly style?: React.CSSProperties;
}

export class TableRow extends React.Component<TableRowProps> {
  render() {
    return <tr style={this.props.style}>{this.props.children}</tr>;
  }
}

export class TableHeading extends React.Component {
  render() {
    return <th scope="col">{this.props.children}</th>;
  }
}

export interface TableDataProps {
  readonly align?: HorizontalAlignment;
  readonly colSpan?: number;
}

export class TableData extends React.Component<TableDataProps> {
  render() {
    return (
      <td colSpan={this.props.colSpan} align={this.props.align}>
        {this.props.children}
      </td>
    );
  }
}
