export function requireEnvironmentVariable(name: string, defaultValue?: string): string {
  const value = process.env[name];

  if (typeof value === 'undefined') {
    if (typeof defaultValue === 'undefined') {
      throw new Error(`environment variable undefined: "${name}"`);
    }

    return defaultValue;
  }

  return value;
}
