import React from 'react';

export interface ModalHeaderProps {
  readonly onClose?: () => void;
}

export class ModalHeader extends React.Component<ModalHeaderProps> {
  render() {
    return (
      <div className="modal-header">
        {this.props.children}
        <button type="button" className="close" aria-label="Close" onClick={this.props.onClose}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
  }
}

export class ModalTitle extends React.Component {
  render() {
    return (
      <h5 className="modal-title" id="exampleModalLabel">
        {this.props.children}
      </h5>
    );
  }
}

export class ModalBody extends React.Component {
  render() {
    return <div className="modal-body">{this.props.children}</div>;
  }
}

export interface ModalProps {
  readonly onClose?: () => void;
}

export class Modal extends React.Component<ModalProps> {
  protected modalRoot?: HTMLDivElement | null;

  constructor(props: ModalProps) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.modalRoot && this.modalRoot.focus();
  }

  handleKeyDown(ev: React.KeyboardEvent) {
    if (ev.key === 'Escape') {
      this.props.onClose && this.props.onClose();
    }
  }

  render() {
    return (
      <div
        className="modal fade show"
        id="exampleModal"
        tabIndex={0}
        aria-labelledby="exampleModalLabel"
        style={{ display: 'block' }}
        aria-modal={true}
        role="dialog"
        onKeyDown={this.handleKeyDown}
        ref={(modal) => (this.modalRoot = modal)}
      >
        <div className="modal-dialog">
          <div className="modal-content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export class ModalBackdrop extends React.Component {
  render() {
    return <div className="modal-backdrop fade show"></div>;
  }
}
