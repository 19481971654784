import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { AppState, Event } from '../../reducer';
import * as actions from '../../actions';
import { Form, FormGroup, TextInput, DateInput } from '../form';

interface EventFormStateProps {
  readonly event: Event;
  readonly index?: number;
}

interface EventFormDispatchProps {
  readonly onCancel: () => void;
  readonly onChange: (event: Event) => void;
  readonly onCreate: (event: Event) => void;
  readonly onUpdate: (event: Event, index: number) => void;
}

function mapStateToProps(state: AppState): EventFormStateProps {
  if (!state.form) {
    throw new Error('state.form undefined');
  }

  return {
    event: state.form.attributes as Event,
    index: state.form.index
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<{}, {}, AnyAction>): EventFormDispatchProps {
  return {
    onCancel: () => dispatch(actions.cancelMetadataEvent()),
    onChange: (event) => dispatch(actions.changeMetadataEvent(event)),
    onCreate: (event) => dispatch(actions.createMetadataEvent(event)),
    onUpdate: (event, index) => dispatch(actions.updateMetadataEvent(event, index))
  };
}

export type EventFormProps = EventFormStateProps & EventFormDispatchProps;

export class EventForm extends React.Component<EventFormProps> {
  constructor(props: EventFormProps) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(ev: React.SyntheticEvent) {
    ev.preventDefault();
    if (typeof this.props.index === 'undefined') {
      this.props.onCreate(this.props.event);
    } else {
      this.props.onUpdate(this.props.event, this.props.index);
    }
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <DateInput
            name="date"
            label="Date:"
            value={this.props.event.date}
            onChange={(ev) => this.props.onChange({ ...this.props.event, date: ev.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            name="venue"
            label="Venue:"
            value={this.props.event.venue}
            onChange={(ev) => this.props.onChange({ ...this.props.event, venue: ev.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            name="city"
            label="City:"
            value={this.props.event.city}
            onChange={(ev) => this.props.onChange({ ...this.props.event, city: ev.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            name="url"
            label="URL:"
            value={this.props.event.url}
            onChange={(ev) => this.props.onChange({ ...this.props.event, url: ev.target.value })}
          />
        </FormGroup>
        <div className="float-right">
          <button
            type="button"
            className="btn btn-secondary mr-2"
            data-dismiss="modal"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-success">
            Save
          </button>
        </div>
      </Form>
    );
  }
}

export const ConnectedEventForm = connect(mapStateToProps, mapDispatchToProps)(EventForm);
